import React, { useState, useEffect } from 'react';

// Import external functions
import * as helpers from '../../../../helpers/Combination_helpers';
import * as other_helpers from '../../../../helpers/Other_helpers';
import Combination from './Combination';
import CombinationMain from './CombinationMain';
import * as turf from '@turf/turf';

// Import CSS & assets
import './Combinations.css';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';


const Combinations = ({ capacity, setCapacity, mapLayers, setMapLayers, highlightedCombination, setHighlightedCombination }) => {

    const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const get_simulation = () => {
        var buildable = capacity.buildable;
        buildable.combinations = "fetching";
        setCapacity({ ...capacity, buildable: buildable, toSave: { autoSave: false, action: "", value: "" } });
        setSelectedZone(0);
    }

    const [combiFullList, setCombiFullList] = useState([]);
    const [combiSortedList, setCombiSortedList] = useState([]);
    useEffect(() => {
        if (capacity?.buildable?.combinations) {
            // console.log("NEW COMBINATIONS", capacity.buildable.combinations);
            setCombiFullList(capacity.buildable.combinations);
            setCombiSortedList(capacity.buildable.combinations);
        }
        else {
            setCombiFullList([]);
            setCombiSortedList([]);
        }
    }, [capacity?.buildable?.combinations]);

    const [orderedBy, setOrderedBy] = useState("byId");
    const orderCombiList = () => {
        var orderingType = document.getElementById('orderBy').value;
        setOrderedBy(orderingType);
        console.log("ORDER LIST BY", orderingType);
        var sortedArray_full = [];
        capacity.buildable.combinations.forEach(combinatoions_group => {

            if (orderingType === "byArea") {
                var originalArray = [...combinatoions_group];
                var sortedArray = originalArray.sort(function (a, b) {
                    var x = a.area; var y = b.area;
                    return ((x < y) ? 1 : ((x > y) ? -1 : 0));
                });
                sortedArray_full.push(sortedArray);
            }
            else if (orderingType === "byGroundArea") {
                var originalArray = [...combinatoions_group];
                var sortedArray = originalArray.sort(function (a, b) {
                    var x = a.groundArea; var y = b.groundArea;
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                });
                sortedArray_full.push(sortedArray);
            }
            else if (orderingType === "byCompacity") {
                var originalArray = [...combinatoions_group];
                var sortedArray = originalArray.sort(function (a, b) {
                    var x = (a.facade_area / a.area); var y = (b.facade_area / b.area);
                    return ((x < y) ? 1 : ((x > y) ? -1 : 0));
                });
                sortedArray_full.push(sortedArray);
            }
            else if (orderingType === "byNbBuildings") {
                var originalArray = [...combinatoions_group];
                var sortedArray = originalArray.sort(function (a, b) {
                    var x = a.buildings.length; var y = b.buildings.length;
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                });
                sortedArray_full.push(sortedArray);
            }
            else {
                sortedArray_full = capacity.buildable.combinations;
            }

        })
        setCombiSortedList(sortedArray_full);

        scrollToListTop(false);
    }

    // PARAMETERS
    const getBuildingWidth = () => {
        if (capacity?.buildable?.volume?.parameters?.max_building_width) {
            return capacity?.buildable?.volume?.parameters?.max_building_width;
        }
        else {
            return 10;
        }
    }
    const updateBuildingWidth = (newValue) => {
        setBuildingWidth(newValue);
        resetList();
        var buildable = { ...capacity.buildable };
        if (buildable.volume?.parameters?.max_building_width) {
            buildable.volume.parameters.max_building_width = parseFloat(newValue);
        }
        buildable.combinations = ["removed"];
        setCapacity({ ...capacity, buildable: buildable });
    }
    const [buildingWidth, setBuildingWidth] = useState(getBuildingWidth());

    const getBuildingOffsetMain = () => {
        if (capacity?.buildable?.volume?.parameters?.min_offset_facade_main) {
            return capacity?.buildable?.volume?.parameters?.min_offset_facade_main;
        }
        else {
            return { type: "recul", value: 6 };
        }
    }
    const updateBuildingOffsetMain = (newValue) => {
        setBuildingOffsetMain(newValue);
        resetList();
        var buildable = { ...capacity.buildable };
        if (buildable.volume?.parameters?.min_offset_facade_main?.value) {
            buildable.volume.parameters.min_offset_facade_main.value = parseFloat(newValue);
        }
        buildable.combinations = ["removed"];
        setCapacity({ ...capacity, buildable: buildable });
    }
    const [buildingOffsetMain, setBuildingOffsetMain] = useState(getBuildingOffsetMain());

    const getBuildingOffsetEdge = () => {
        if (capacity?.buildable?.volume?.parameters?.min_offset_facade_edge) {
            return capacity?.buildable?.volume?.parameters?.min_offset_facade_edge;
        }
        else {
            return { type: "recul", value: 4 };
        }
    }
    const updateBuildingOffsetEdge = (newValue) => {
        setBuildingOffsetEdge(newValue);
        resetList();
        var buildable = { ...capacity.buildable };
        if (buildable.volume?.parameters?.min_offset_facade_edge?.value) {
            buildable.volume.parameters.min_offset_facade_edge.value = parseFloat(newValue);
            buildable.volume.parameters.min_offset_facade_edge.type = "recul";
            buildable.volume.parameters.min_offset_facade_edge.origin = "user";
        }
        buildable.combinations = ["removed"];
        setCapacity({ ...capacity, buildable: buildable });
    }
    const [buildingOffsetEdge, setBuildingOffsetEdge] = useState(getBuildingOffsetEdge());


    // FUNCTIONS
    const scrollToListTop = (ifHigher) => {
        var topList = document.getElementById("combinations_list").offsetTop;
        var topSection = document.getElementById("section_6").offsetTop;
        var heightHeader = document.getElementById("combinations_selection").offsetHeight;
        var heightSelected = document.getElementById("combinations_header").offsetHeight;
        var scrollTarget = topSection + topList - heightSelected - heightHeader + 98;

        if (ifHigher === true || document.getElementById("section_data").scrollTop > scrollTarget) {
            document.getElementById("section_data").scrollTop = scrollTarget;
        }
    }

    // ZONES
    const [selectedZone, setSelectedZone] = useState(0);

    const changeSelectedZone = (new_zone) => {
        if (new_zone !== selectedZone) {
            setSelectedZone(new_zone);
            setIndicatorOffset({ width: document.getElementById("zone_title_" + new_zone).offsetWidth - 0, left: document.getElementById("zone_title_" + new_zone).offsetLeft + 0 })
            setTimeout(() => {
                scrollToListTop(false);
            }, 300);
        }
    }
    const [indicatorOffset, setIndicatorOffset] = useState({ width: 40, left: 0 });
    const resetList = () => {
        setIndicatorOffset({ width: 40, left: 0 });
        setHighlightedCombination([]);
        setOrderedBy("byId");
    }
    const [unavailableSimu, setUnavailableSimu] = useState({});
    const getUnavailableSimu = () => {
        var new_unavailable = {};
        if (capacity?.buildable?.combinations && capacity?.buildable?.combinations.length > 1 && highlightedCombination.length > 0) {
            // // Get zones not to check
            // var zones_not_to_check = [];
            // highlightedCombination.forEach(item => {
            //     zones_not_to_check.push(letters.indexOf(item.id.split("-")[0]));
            // })
            // Create checking buffer
            var selected_ids = [];
            highlightedCombination.forEach(selected => {
                selected_ids.push(selected.id);
            })
            var check_buffer = [];
            var simu_found = 0;
            for (var i = 0; i < capacity?.buildable?.combinations.length; i++) {
                for (var j = 0; j < capacity?.buildable?.combinations[i].length; j++) {
                    if (selected_ids.includes(capacity?.buildable?.combinations[i][j].id)) {
                        var current_buffers = [...capacity?.buildable?.combinations[i][j].offset_buffers];
                        current_buffers.forEach(buffer => {
                            buffer.properties.from = capacity?.buildable?.combinations[i][j].id;
                        })
                        check_buffer = check_buffer.concat(current_buffers);
                        simu_found++;
                        break;
                    }
                }
                if (simu_found >= selected_ids.length) {
                    break;
                }
            }
            // Loop th zones to process checking
            capacity?.buildable?.combinations.forEach((zone, zone_index) => {
                // if (!zones_not_to_check.includes(zone_index)) {
                // Loop th all simu of current zone
                zone.forEach((simu, simu_index) => {
                    // Loop th all buildings of simu
                    var unavailable = false;
                    for (var building_index = 0; building_index < simu.buildings.length; building_index++) {
                        // Get intersection wit all check_buffer
                        for (var check_buffer_index = 0; check_buffer_index < check_buffer.length; check_buffer_index++) {
                            // Do not check against same zone selected simu
                            if (letters.indexOf(check_buffer[check_buffer_index].properties.from.split("-")[0]) === zone_index) {
                                continue
                            }
                            // Get intersection
                            var intersection = turf.intersect(simu.buildings[building_index].levels[0].polygon, check_buffer[check_buffer_index]);
                            if (intersection !== null) {
                                var intersection_area = turf.area(intersection);
                                if (intersection_area >= 1) {
                                    new_unavailable[simu.id] = { from: check_buffer[check_buffer_index].properties.from, area: intersection_area }
                                    unavailable = true;
                                }
                            }
                        }

                        if (unavailable === true) {
                            break;
                        }
                    }
                })
                // }
            })
        }
        // Set result
        setUnavailableSimu(new_unavailable);
    }
    useEffect(() => {
        getUnavailableSimu();
    }, [highlightedCombination]);



    return (
        <div className="combi__container">
            {/* PARAMETERS */}
            <div className="app__sectiondata_widget_title">Paramètres des simulations</div>
            <div className="app__sectiondata_widget_layout_A1">
                <div className="app__sectiondata__widget">
                    <div className="app__sectiondata_widget_value_container">
                        <div className="app__sectiondata_widget_title">Largeur de bâtiment</div>
                        <div className="app__sectiondata_widget_value_text_medium">
                            <input className="buildable__input" type="number" id="building_width" name="building_width" min="1" max="100" step="1" defaultValue={buildingWidth} onBlur={(e) => { updateBuildingWidth(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById('building_width').blur() } }}></input>
                        </div>
                        <div className="app__sectiondata_widget_value_legend_medium" style={{ color: "#007384" }}>m</div>
                    </div>
                </div>
                <div className="app__sectiondata__widget">
                    <div className="app__sectiondata_widget_title">Retraits en façade</div>
                    <div className="app__sectiondata_widget_layout_A">
                        {capacity?.buildable?.volume?.parameters?.min_offset_facade_main?.origin === "user" ?
                            <div>
                                <div className="app__sectiondata_widget_value_text_medium">
                                    <input className="buildable__input" type="number" id="building_offset_main" name="building_offset_main" min="0" max="100" step="1" defaultValue={buildingOffsetMain.value} onBlur={(e) => { updateBuildingOffsetMain(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById('building_offset_main').blur() } }}></input>
                                </div>
                                <div className="app__sectiondata_widget_value_legend_medium" style={{ color: "#007384" }}>m en façade principale</div>
                            </div>
                            :
                            <div style={{ position: "relative" }}>
                                <div className="app__sectiondata_widget_value_text_medium">{other_helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.min_offset_facade_main?.value).toFixed(2))}</div>
                                <div className="app__sectiondata_widget_value_legend_medium">m en façade principale</div>
                            </div>
                        }
                        {capacity?.buildable?.volume?.parameters?.min_offset_facade_edge?.origin === "user" ?
                            <div>
                                <div className="app__sectiondata_widget_value_text_medium">
                                    <input className="buildable__input" type="number" id="building_offset_edge" name="building_offset_edge" min="0" max="100" step="1" defaultValue={buildingOffsetEdge.value} onBlur={(e) => { updateBuildingOffsetEdge(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById('building_offset_edge').blur() } }}></input>
                                </div>
                                <div className="app__sectiondata_widget_value_legend_medium" style={{ color: "#007384" }}>m en façade pignon</div>
                            </div>
                            :
                            <div style={{ position: "relative" }}>
                                <div className="app__sectiondata_widget_value_text_medium">{other_helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.min_offset_facade_edge?.value).toFixed(2))}</div>
                                <div className="app__sectiondata_widget_value_legend_medium">m en façade pignon</div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            {/* LIST */}
            {(capacity?.buildable?.combinations && capacity.buildable.combinations !== "fetching" && capacity.buildable.combinations.length > 0 && capacity.buildable.combinations[0] !== "removed") ?
                <>
                    {(combiSortedList.length > 0 && Array.isArray(combiSortedList[0]) && combiSortedList[0].length > 0) ?
                        // {(capacity?.buildable?.combinations && capacity.buildable.combinations.length > 0) &&
                        <>
                            <div id="combinations_selection" className="app__sectiondata_widget_sticky">
                                <div className="app__sectiondata_widget_spacer_30" style={{ backgroundColor: "#F5F5F5" }}></div>
                                <div className="app__sectiondata_widget_title">Simulation sélectionnée</div>
                                {highlightedCombination.length === 0 ?
                                    <div className="buildable__selected_container_placeholder">
                                        <div style={{ textAlign: "center" }}>
                                            Sélectionnez {capacity.buildable.combinations.length > 1 ? "des simulations" : "une simulation"} dans<span className="app__ruleset_rule_condition_link" style={{ margin: "0px 6px" }} onClick={() => { scrollToListTop(true) }}> la liste ci-dessous </span>pour voir les détails de {capacity.buildable.combinations.length > 1 ? "celles-ci" : "celle-ci"}.
                                        </div>
                                    </div>
                                    :
                                    <CombinationMain capacity={capacity} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} />

                                }
                                <div className="app__sectiondata_widget_spacer_20" style={{ backgroundColor: "#F5F5F5" }}></div>
                                <div id="combinations_header" className="combi__title_sticky">
                                    <div className="combi_title_container">
                                        {/* <div className="app__sectiondata_widget_title">Liste des simulations ({capacity.buildable.combinations[0].length})</div> */}
                                        <div className="app__sectiondata_widget_title">Liste des simulations ({capacity.buildable.combinations.reduce((acc, element) => acc + element.length, 0)})</div>
                                        <div id="combinations_list_arrow" className="combi__title_sticky_img" onClick={() => { scrollToListTop(true) }}><img src={ico_arrow} alt="arrow_up" /></div>
                                        <div className="app__sectiondata_widget_title_subcontainer">
                                            <div className="app__sectiondata_widget_title">Trier par :</div>
                                            <select className="app__sectiondata_widget_title_dropdown" id="orderBy" onChange={orderCombiList}>
                                                <option value="byId">ID</option>
                                                <option value="byArea">Surface construite (décroissant)</option>
                                                <option value="byGroundArea">Emprise au sol (croissant)</option>
                                                <option value="byCompacity">Compacité (décroissant)</option>
                                                <option value="byNbBuildings">Nombre de bâtiments (croissant)</option>
                                            </select>
                                        </div>
                                    </div>
                                    {capacity.buildable.combinations.length > 1 &&
                                        <div className="app__combi_zone_menu_container">
                                            <div className="app__combi_zone_menu">
                                                {combiSortedList.map((zone, zone_index) => {
                                                    const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                                                    return (
                                                        <div id={"zone_title_" + zone_index} className={`app__combi_zone_menu_title ${selectedZone === zone_index ? "app__combi_zone_menu_title_selected" : ""}`} onClick={() => { changeSelectedZone(zone_index) }}>Zone {letters[zone_index]}</div>
                                                    )
                                                })}
                                                <div className="app__combi_zone_menu_indicator" style={{ left: indicatorOffset.left + "px", width: indicatorOffset.width + "px" }}></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div id="combinations_list" className="combi__grid_container">
                                {combiSortedList.map((zone, zone_index) => {
                                    return (
                                        <div className="combi__grid" style={{ transform: 'translateX(calc(' + (selectedZone * -100) + '% - ' + (selectedZone * 30) + 'px))' }}>
                                            {(combiSortedList.length > 0 && Array.isArray(combiSortedList[0]) && combiSortedList[0].length > 0) &&
                                                <>
                                                    {combiSortedList[zone_index].map((combi, index) => {
                                                        return (
                                                            <Combination combination={combi} capacity={capacity} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} index={index} orderedBy={orderedBy} unavailableSimu={unavailableSimu} />
                                                        )
                                                    })}
                                                    {window.innerWidth <= 550 &&
                                                        <div className="app__sectiondata_widget_spacer_10"></div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div className="app__sectiondata_loading_container_medium2">
                            <div style={{ fontSize: "26px" }}>😓</div>
                            <div className="app__sectiondata_loading_text">Nous n'avons trouvé aucune simulation valide.<br></br>Essayez de modifier les paramètres ou les règles PLU afin de relancer le calcul.</div>
                        </div>
                    }
                </>
                :
                capacity?.buildable?.combinations === "fetching" ?
                    <div className="app__sectiondata_loading_container_medium2">
                        <div className="app__sectiondata_loading"></div>
                        <div className="app__sectiondata_loading_text">Nous calculons les simulations de construction</div>
                    </div>
                    :
                    <div className="app__sectiondata_loading_container_medium2">
                        <div className="app__sectiondata_loading_text">Les règles PLU ou les paramètres ont été modifiés</div>
                        <div className="combi__launch_button" onClick={() => { get_simulation() }}>Lancer le calcul des simulations</div>
                    </div>
            }
        </div>
    );
};

export default Combinations;