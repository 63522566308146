import React, { useState, useEffect } from 'react';

// Import CSS & assets
import './Buildings.css';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';
import fade from '../../../../assets/fade_grey.svg';

// Import external functions
import * as other_helpers from '../../../../helpers/Other_helpers';

// Import components
import DoughnutChart from '../../../charts/DoughnutChart';
import Building from './Building';


const Buildings = ({ capacity, setCapacity, mapLayers, setMapLayers, setBuildingUpdateTracker }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }


    // CHART
    const pieChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start'
            },
        },
        cutout: 60
    }
    var chartData = {
        labels: ['Surface imperméable', 'Surface perméable'],
        datasets: [
            {
                label: 'Surfaces',
                data: [capacity?.landBase?.buildings?.buildings_land_area || 0, (capacity?.landBase?.union?.area - capacity?.landBase?.buildings?.buildings_land_area) || 0],
                backgroundColor: [
                    '#062134',
                    '#E5E5E5',
                ],
                hoverBackgroundColor: [
                    '#062134',
                    '#E5E5E5',
                ],
                borderColor: "#D9D9D9",
                hoverBorderColor: "#D9D9D9",
                borderWidth: 0,
                hoverBorderWidth: 0,
            },
        ],
    };


    const [buildingsDetail, setBuildingsDetail] = useState(false);
    const handleBuildingsDetail = () => {
        if (buildingsDetail === true) {
            // Scroll
            var topList = document.getElementById("buildings_anchor").offsetTop;
            var topSection = document.getElementById("section_1").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setBuildingsDetail(!buildingsDetail);
    }


    return (
        <>
            {(capacity?.landBase?.buildings?.buildings_land && capacity.landBase.buildings.buildings_land.length > 0) &&
                <div>
                    <div className="app__sectiondata_widget_spacer_30"></div>
                    <div id="buildings_anchor" className="app__sectiondata_widget_title_container">
                        <div className="app__sectiondata_widget_title">Constructions existantes sur le terrain</div>
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_buildings_ign_land" checked={mapLayers.IGN_Buildings.isVisible} onChange={() => { layer_Toggle("IGN_Buildings") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_buildings_ign_land">Toggle</label></div>
                        </div>
                    </div>
                    <div id="buildings_list" className={`app__sectiondata_buildings_container ${(!buildingsDetail && capacity.landBase.buildings.buildings_land.length > 3) ? "app__sectiondata_buildings_container_small" : ""}`}>
                        {capacity.landBase.buildings.buildings_land.map((item_index, index) => {
                            return (
                                <Building key={"building_" + index} capacity={capacity} setCapacity={setCapacity} setBuildingUpdateTracker={setBuildingUpdateTracker} item={capacity.landBase.buildings.buildings[item_index]} item_index={item_index} index={index} />
                            )

                        })}
                    </div>
                    {/* HIDE SHOW */}
                    {capacity.landBase.buildings.buildings_land.length > 3 &&
                        <div className="app__context_housing_detail_button_container2">
                            {!buildingsDetail ?
                                <img className="app__fade" src={fade} alt="" />
                                :
                                <div className="app__sectiondata_widget_spacer_10"></div>
                            }
                            <div className="app__context_housing_detail_button_container" onClick={handleBuildingsDetail}>
                                {buildingsDetail &&
                                    <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                                <div className="app__context_housing_detail_button">{buildingsDetail ? 'Afficher moins de constructions' : 'Afficher les ' + capacity.landBase.buildings.buildings_land.length + ' constructions'}</div>
                                {!buildingsDetail &&
                                    <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                            </div>
                        </div>
                    }
                    {/* INFO */}
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : IGN BD Topo, Base de Données Nationale des Bâtiments et CSTB
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                    {/* IMPERMEABILITY */}
                    {(capacity?.landBase?.union?.area && capacity?.landBase?.buildings?.buildings_land_area) &&
                        <>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__sectiondata_widget_title">Coefficient d'imperméabilisation du terrain</div>
                            <div className="app__buidings_coef_body app__sectiondata__widget">
                                {window.innerWidth > 500 &&
                                    <div className="app__buildings_chart">
                                        <div className="app__buildings_chart_value">≥<span style={{ fontSize: '32px', fontWeight: '600', marginLeft: '4px', marginRight: '4px' }}>{other_helpers.get_pretty_num((100 * capacity?.landBase?.buildings?.buildings_land_area / capacity?.landBase?.union?.area).toFixed(1))}</span>%</div>
                                        <DoughnutChart chartData={chartData} chartOptions={pieChartOptions} />
                                    </div>
                                }
                                <div className="app__buidings_coef_text">
                                    <div>Le terrain fait <strong>{other_helpers.get_pretty_num(capacity?.landBase?.union?.area.toFixed(2))}m²</strong> et présente une surface construite de <strong>{other_helpers.get_pretty_num(capacity?.landBase?.buildings?.buildings_land_area.toFixed(2))}m²</strong> (sans compter les voiries, ou autres surfaces imperméables extérieures non connues).</div>
                                    <div>Ce qui représente un coefficient d'imperméabilisation d'une valeur au moins égale à <strong>{other_helpers.get_pretty_num((100 * capacity?.landBase?.buildings?.buildings_land_area / capacity?.landBase?.union?.area).toFixed(1))}%</strong>.</div>
                                </div>
                            </div>
                        </>
                    }
                    {/* CHALEUR */}
                    {(capacity?.landBase?.location?.chaleur && capacity?.landBase?.location?.chaleur?.isEligible === true) &&
                        <>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__sectiondata_widget_title">Raccordement au réseau de chauffage urbain</div>
                            <div className="app__sectiondata__widget">
                                {capacity?.landBase?.location?.chaleur?.isEligible === true &&
                                    <div>Un réseau de chaleur urbain est détecté à proximité du terrain. Ce réseau est géré par {capacity?.landBase?.location?.chaleur?.gestionnaire}.</div>
                                }
                                {capacity?.landBase?.location?.chaleur?.inPDP === true &&
                                    // <div>Le terrain se situe dans le périmètre de développement prioritaire d'un réseau classé. <strong>Le raccordement à ce réseau est obligatoire pour certains bâtiments.</strong></div>
                                    <div><strong>Le raccordement à ce réseau est obligatoire pour certains bâtiments.</strong></div>
                                }
                                {capacity?.landBase?.location?.chaleur?.id &&
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                        <div className="app__land_data_button" style={{ width: "160px" }}><a href={"https://france-chaleur-urbaine.beta.gouv.fr/reseaux/" + capacity?.landBase?.location?.chaleur?.id} target="_blanck">Afficher le détail du réseau</a></div>
                                    </div>
                                }
                                {/* inPDP:
                                type: boolean
                                description: true si l'adresse testée se situe dans le périmètre de développement prioritaire d'un réseau classé (zone où s'applique une obligation de raccordement pour certains bâtiments) */}
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
};

export default Buildings;