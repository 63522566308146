import React, { useState, useEffect } from 'react';

// Import external functions
import * as sru_helpers from './sru_helpers';
import * as helpers from '../../../../helpers/Other_helpers';
import './Sru.css';



const SRU = ({ capacity, setCapacity }) => {

    const [zoneDetails, setZonedetails] = useState({
        nb_log: null,
        sru_current: null,
        sru_target: null,
        exempted: null
    });
    useEffect(() => {
        setZonedetails(sru_helpers.get_sru(capacity?.city?.inseecode));
    }, [capacity]);


    return (
        <>
            {(capacity?.city?.inseecode && zoneDetails?.sru_target !== null) &&
                <>
                    <div className="app__sectiondata_widget_title">Données SRU (Solidarité et Renouvellement Urbain)</div>
                    <div className="app__sru_container">
                        {/* <div className="app__sectiondata_widget_layout_B" style={{ gap: 10 }}>
                            <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_value_text_small">Zone {zoneDetails.sru_current}</div>
                            </div>
                        </div> */}
                        {/* <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__land_data_detail_value">Défini à l’article D304-1 du code de la construction et de l’habitation, le zonage conventionnellement appelé ABC effectue un « classement des communes du territoire national en zones géographiques en fonction du déséquilibre entre l'offre et de la demande de logements ».</div> */}
                        {zoneDetails?.nb_log !== null &&
                            <div className="app__sectiondata__widget" style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="app__sectiondata_widget_title">Nombre de logements sociaux</div>
                                <div className="app__sectiondata_widget_value_container">
                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((zoneDetails.nb_log).toFixed(0))}</div>
                                    <div className="app__sectiondata_widget_value_legend">logements sociaux</div>
                                </div>
                            </div>
                        }
                        {zoneDetails?.sru_current !== null ?
                            <div className="app__sectiondata__widget" style={{ flex: '1', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: "60%" }}>
                                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div className="app__sectiondata_widget_title">Taux actuel</div>
                                    <div className="app__sectiondata_widget_value_container">
                                        <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((zoneDetails.sru_current * 100).toFixed(2))}</div>
                                        <div className="app__sectiondata_widget_value_legend">%</div>
                                    </div>
                                </div>
                                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div className="app__sectiondata_widget_title">Taux légal à atteindre</div>
                                    <div className="app__sectiondata_widget_value_container">
                                        <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((zoneDetails.sru_target * 100).toFixed(0))}</div>
                                        <div className="app__sectiondata_widget_value_legend">%</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="app__sectiondata__widget" style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="app__sectiondata_widget_title">Taux légal à atteindre</div>
                                <div className="app__sectiondata_widget_value_container">
                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((zoneDetails.sru_target * 100).toFixed(0))}</div>
                                    <div className="app__sectiondata_widget_value_legend">%</div>
                                </div>
                            </div>
                        }
                    </div>
                    {zoneDetails?.sru_current !== null &&
                        <>
                            <div className="app__sectiondata_widget_spacer_15"></div>
                            <div className="app__sectiondata__widget">
                                {/* <div className="app__sectiondata_widget_title">Bilan</div> */}
                                <div className="app__sectiondata_widget_value_container">
                                    <div className="">La commune <strong>{zoneDetails?.sru_current >= zoneDetails?.sru_target ? "remplit" : "ne remplit pas"} ses obligations</strong> en terme de logements sociaux, et <strong>{zoneDetails?.exempted === true ? "est exemptée" : "n'est pas exemptée"}</strong> pour la période 2023-2025.</div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : Ministère de la Transition écologique - Millésime 2023
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                    <div className="app__sectiondata_widget_spacer_30"></div>
                </>
            }
        </>
    );
};

export default SRU;