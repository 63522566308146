import React from 'react';

// Import components
import LineChart from '../../../charts/LineChart';

// Import CSS & assets
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import './Topo.css'

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';


const Topo = ({ capacity, mapLayers, setMapLayers }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }

    // Prepare line chart
    var chartData = {};
    if (capacity?.landBase?.topo?.profile) {
        var labels = [];
        var data = [];
        var bg = [];
        for (var i = 0; i < capacity.landBase.topo.profile.elevations.length; i++) {
            labels.push("-" + i);
            // data.push(capacity.landBase.topo.profile.elevations[i].z);
            data.push({ x: capacity.landBase.topo.profile.elevations[i].distance, y: capacity.landBase.topo.profile.elevations[i].z });
            if (capacity.landBase.topo.profile.elevations[i].z === capacity.landBase.topo.point_max[2] && capacity.landBase.topo.profile.elevations[i].lon === capacity.landBase.topo.point_max[0]) {
                bg.push('#FF0000');
            }
            else if (capacity.landBase.topo.profile.elevations[i].z === capacity.landBase.topo.point_min[2] && capacity.landBase.topo.profile.elevations[i].lon === capacity.landBase.topo.point_min[0]) {
                bg.push('#0000FF');
            }
            else {
                bg.push('#062134');
            }
        }
        chartData = {
            // labels: labels,
            datasets: [
                {
                    label: 'Altitude',
                    data: data,
                    pointBackgroundColor: bg,
                    // backgroundColor: 'rgba(6,33,52,0.5)',
                    backgroundColor: (context) => {
                        const bgColor = [
                            'rgba(6,33,52,0.5)',
                            'rgba(6,33,52,0.3)',
                            'rgba(6,33,52,0)',
                        ]
                        if (!context?.chart?.chartArea) { return }
                        const { ctx, data, chartArea: { top, bottom } } = context.chart;
                        const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                        gradientBg.addColorStop(0, bgColor[0]);
                        gradientBg.addColorStop(0.5, bgColor[1]);
                        gradientBg.addColorStop(1, bgColor[2]);
                        return gradientBg
                    },
                    fill: true,
                },
            ],
        };
    }
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        interaction: {
            intersect: false,
            mode: 'nearest',
            axix: 'x',
        },
        elements: {
            point: {
                borderWidth: 0,
                hoverBorderWidth: 0,
                radius: 2.5,
            },
            line: {
                tension: 0.3,
                borderWidth: 2,
                borderColor: '#062134',
            },
        },
        scales: {
            xAxis: {
                type: 'linear',
                min: -0.5,
                max: (capacity?.landBase?.topo?.profile?.elevations[capacity?.landBase?.topo?.profile?.elevations.length - 1].distance) + 0.5,
                display: false,
            },
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value.toFixed(1) + " m NGF";
                    }
                }
            }
        }
    }

    return (
        <>
            {(capacity?.landBase?.topo && capacity.landBase.topo !== "fetching" && capacity.landBase.topo !== "error") &&
                <>
                    <div className="app__sectiondata_widget_spacer_50"></div>
                    <div className="app__sectiondata_widget_title_container">
                        <div className="app__sectiondata_widget_title">Topographie</div>
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_topo_3D" checked={mapLayers.topo_3D.isVisible} onChange={() => { layer_Toggle("topo_3D") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_topo_3D">Toggle</label></div>
                        </div>
                    </div>
                    <div>
                        {/* <div className="app__sectiondata_widget_layout_B"> */}
                        {/* <div className={`${window.innerWidth > 550 ? "app__sectiondata_widget_layout_A" : "app__sectiondata_widget_layout_B"}`}> */}
                        {capacity?.landBase?.topo?.height && capacity?.landBase?.topo?.slide &&
                            <div className="app__sectiondata_widget_layout_A">
                                <div className="app__sectiondata__widget" style={{ flex: 1 }}>
                                    <div className="app__sectiondata_widget_layout_A">
                                        <div>
                                            <div className="app__sectiondata_widget_title">Dénivelé max</div>
                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity?.landBase?.topo?.height).toFixed(2))}</div>
                                            <div className="app__sectiondata_widget_value_legend">m</div>
                                        </div>
                                        <div>
                                            <div className="app__sectiondata_widget_title">Pente max</div>
                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity?.landBase?.topo?.slide).toFixed(2))}</div>
                                            <div className="app__sectiondata_widget_value_legend">%</div>
                                        </div>
                                    </div>
                                </div>
                                {window.innerWidth > 550 &&
                                    <div className="app__sectiondata__widget" style={{ width: '30%' }}>
                                        <div className="app__sectiondata_widget_title">Déclivité</div>
                                        <div className="app__sectiondata_widget_value_text" style={{ fontSize: '34px' }}>{capacity.landBase.topo.slide < 8 ? "Douce" : capacity.landBase.topo.slide <= 10 ? "Modérée" : "Forte"}</div>
                                        <div className="app__sectiondata_widget_value_legend">{capacity.landBase.topo.slide < 8 ? "Pente < 8%" : capacity.landBase.topo.slide < 10 ? "Pente entre 8 et 10%" : "Pente > 10%"}</div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="app__sectiondata_widget_spacer_20"></div>
                        <div className="app__sectiondata_widget_title_container">
                            <div className="app__sectiondata_widget_title">Points topo</div>
                            {capacity?.landBase?.topo?.elevation_mean &&
                                <div className="app__sectiondata_widget_layer">
                                    <img src={ico_map_layers} alt="ico_map_layers" />
                                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_topo_points" checked={mapLayers.topo_points.isVisible} onChange={() => { layer_Toggle("topo_points") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_topo_points">Toggle</label></div>
                                </div>
                            }
                        </div>
                        <div className="app__sectiondata__widget">
                            <div className="app__sectiondata_widget_layout_A">
                                <div>
                                    <div className="app__sectiondata_widget_title">Point bas <span style={{ color: 'blue', fontWeight: 900 }}>•</span></div>
                                    <div className="app__sectiondata_widget_value_text" style= {{fontSize: window.innerWidth > 550 ? "" : "36px"}}>{helpers.get_pretty_num((capacity.landBase.topo.point_min[2]).toFixed(2))}</div>
                                    <div className="app__sectiondata_widget_value_legend">m NGF</div>
                                </div>
                                {capacity?.landBase?.topo?.elevation_mean &&
                                    <div>
                                        <div className="app__sectiondata_widget_title">Point moyen</div>
                                        <div className="app__sectiondata_widget_value_text" style= {{fontSize: window.innerWidth > 550 ? "" : "36px"}}>{helpers.get_pretty_num((capacity.landBase.topo.elevation_mean).toFixed(2))}</div>
                                        <div className="app__sectiondata_widget_value_legend">m NGF</div>
                                    </div>
                                }
                                <div>
                                    <div className="app__sectiondata_widget_title">Point haut <span style={{ color: 'red', fontWeight: 900 }}>•</span></div>
                                    <div className="app__sectiondata_widget_value_text" style= {{fontSize: window.innerWidth > 550 ? "" : "36px"}}>{helpers.get_pretty_num((capacity.landBase.topo.point_max[2]).toFixed(2))}</div>
                                    <div className="app__sectiondata_widget_value_legend">m NGF</div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {capacity?.landBase?.topo?.profile &&
                            <>
                                <div className="app__sectiondata_widget_spacer_20"></div>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Profil altimétrique caractéristique</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_topo_profile" checked={mapLayers.topo_profile.isVisible} onChange={() => { layer_Toggle("topo_profile") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_topo_profile">Toggle</label></div>
                                    </div>
                                </div>
                                <div className="app__topo_chart_container">
                                    <LineChart chartData={chartData} chartOptions={chartOptions} />
                                </div>
                                <div className="app__sectiondata_widget_info_container">
                                    <div className="app__sectiondata_widget_info_text">
                                        Source : IGN - Services de calculs altimétriques, précision 2,5m
                                    </div>
                                    <div className="app__sectiondata_widget_info_icon">i</div>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default Topo;