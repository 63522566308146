const version = "1.2";


export const get_environment = () => {
    var current_url = window.location;
    // console.log("current_url", current_url);
    var environment = "DEV";
    if (current_url.href.includes("proplab-develop.netlify.app")) {
        environment = "PREPROD";
    }
    if (current_url.href.includes("proplab.fr")) {
        environment = "PROD";
    }
    return environment
}

export const get_version = () => {
    return version
}