// Import dependencies
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import decode from 'jwt-decode';
import * as release_helpers from './release_helpers';

// Import components
import Header from '../../components/app/header/Header';
import ReleaseItem from './ReleaseItem';

// Import CSS & assets
import './Release.css';
import logo from '../../assets/logo.svg';

// Import variables
import { LOGOUT } from '../../constants/actionTypes';
const isAccessControlled = true;

const Release = ({ version, environment }) => {

    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Set hooks
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [releaseData, setReleaseData] = useState([]);
    const [focusVersion, setFocusVersion] = useState(null);

    function get_version() {
        var focus_version = searchParams.get("version");
        if (focus_version !== null && focus_version !== undefined) {
            // Scroll
            setTimeout(() => {
                const item_dom = document.getElementById("release_" + focus_version);
                if (item_dom) {
                    console.log("Scroll to", item_dom.getBoundingClientRect().top)
                    document.getElementById("release_list").scrollTop = item_dom.getBoundingClientRect().top - 234;
                }
                setTimeout(() => {
                    setFocusVersion(focus_version);
                }, 800);
            }, 2500);
        }
        else {
            setTimeout(() => {
                setFocusVersion(version);
            }, 2500);
        }
    }

    const handleBackHome = () => {
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    const logout = (reason) => {
        // Dispatch log out action
        dispatch({ type: LOGOUT });
        // Go back to home page
        navigate('/auth?type=signin&cause=' + reason + '&source=' + window.location.pathname);
    }

    const get_release_data = () => {
        setReleaseData(release_helpers.get_release_data());
    }


    // ON LOAD
    useEffect(() => {
        if (isAccessControlled) {
            // Get users' token
            const token = user?.token;
            // If token exists
            if (token) {
                // Decode the token
                const decodedToken = decode(token);
                // Check if token is still valid
                if (decodedToken.exp * 1000 < new Date().getTime()) {
                    console.log("User token expired");
                    logout("expired");
                }
                else {
                    console.log("User token valid");
                    get_version()
                    get_release_data();
                }
            }
            else {
                console.log("User not logged in");
                logout();
            }
        }
    }, []);

    // RETURN
    return (
        <div className='auth__auth'>
            {/* <div className='auth__header' style={{display: 'flex'}}>
                <div className="auth__header-container">
                    <div className="auth__header-logo" onClick={handleBackHome}>
                        <div className="auth__header-logo_box">
                            <img src={logo} alt='logo' />
                        </div>
                    </div>
                    <div className="auth__header-close">
                    </div>
                </div>
            </div> */}
            <Header title="Release Notes" isApp={false} userHook={user} isDark={true} isLogo={true}/>
            <div className='release__title_container'>
                <div className='release__title'>Les dernières nouveautés</div>
                <div className='release__subtitle'>version courante : {version}</div>
            </div>
            <div id='release_list' className='release__data_container'>
                {releaseData.map((release, index) => {
                    return (
                        <ReleaseItem release={release} index={index} focus={focusVersion} max_index={releaseData.length - 1} />
                    )
                })}
            </div>
        </div>
    );
};

export default Release;