import plan_topo from '../../assets/release/release_plan_topo.gif';
import point_moyen from '../../assets/release/release_point_moyen.png';
import export_pdf_1 from '../../assets/release/release_export_pdf_1.gif';
import export_ifc_1 from '../../assets/release/release_export_ifc_1.gif';


export const get_release_data = () => {
    const release_data = [
        {
            version: "1.2",
            date: "14/10/2024",
            title: `Amélioration du module "Contexte Topographique"`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Plan topographique",
                        description: "Cette fonctionnalité permet la création et l'affichage du plan topographique. Il affiche tous les points topographiques à l'intérieur du terrain, les bornes topographiques ainsi que les lignes de niveau.\nCe plan est visble en affichant la couche 'Plan topo' sur la carte.\n Chaque point et chaque borne est cliquable, ce qui permet d'afficher les coordionnées exactes de l'élément.\nPour bénéficier de cette fonctionnalité sur vos études existantes, vous devez 'Mettre à jour les données externes' de la section 'Terrain'.",
                        img: {
                            src: plan_topo,
                            max_w: 600
                        },
                    },
                    {
                        title: "Point topographique moyen",
                        description: "Cette fonctionnalité permet le calcul du point topographique moyen du terrain.\nIl est défini en calculant la moyenne des altitudes de tous les points topographiques à l'intérieur du terrain.\nCe point est utilisé comme niveau 0 local du projet.\nPour bénéficier de cette fonctionnalité sur vos études existantes, vous devez 'Mettre à jour les données externes' de la section 'Terrain'.",
                        img: {
                            src: point_moyen,
                            max_w: 600
                        },
                    },
                ],
                updates: [
                    {
                        title: "Mise à jour de l'API Géoplateforme",
                        description: "Cette mise à jour permet à notre application de suivre la migration du service Géoportail vers Géoplateforme.\nNous utilisons les données de cette API pour obtenir les altitudes des points topo du terrain, et calculer les distances couvertes autour du terrain en 5 minutes en voiture et 10 minutes à pied.",
                    },
                ],
                fixes: []
            }
        },
        {
            version: "1.1",
            date: "03/10/2024",
            title: `Première version des modules "Export PDF" et "Export IFC"`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Export PDF",
                        description: "Cette fonctionnalité permet d'exporter les informations de l'étude capacitaire sous forme de rapport PDF.\nDeux templates sont disponibles : un template standard 'Proplab' et un tempate personnalisé qui s'adapte au logo et aux couleurs de votre société.\nCette première version du module d'export permet d'exporter les données concernant la localisation du terrain.",
                        img: {
                            src: export_pdf_1,
                            max_w: 600
                        },
                    },
                    {
                        title: "Export IFC",
                        description: "Cette fonctionnalité permet d'exporter certains éléments de l'étude capacitaire dans une maquette BIM au format IFC.\nNotre module d'export IFC génère des fichiers certifiés valides par Building Smart International (cf. https://www.buildingsmart.org/users/services/ifc-validation-service).\nCette première version du module d'export permet d'exporter les données concernant la parcelle et la topographie 3D, au format IFC 2X3.",
                        img: {
                            src: export_ifc_1,
                            max_w: 600
                        },
                    },
                ],
                updates: [],
                fixes: []
            }
        },
        {
            version: "1.0.8",
            date: "25/09/2024",
            title: "Géolocalisation",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.7",
            date: "18/09/2024",
            title: "Intégration des données SITADEL",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.6",
            date: "30/08/2024",
            title: "Intégration des données SRU",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.5",
            date: "29/08/2024",
            title: "Prise en compte des parkings dans les règles PLU",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.3",
            date: "01/08/2024",
            title: "Correction d'un bug sur la génération 3D des prospects",
            type: "corrective",
            detail: {}
        },
        {
            version: "1.0.2",
            date: "26/07/2024",
            title: "Possibilité de dupliquer une étude capacitaire",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0",
            date: "26/07/2024",
            title: "Première version de l'application",
            type: "majeure",
            detail: {}
        },
    ]

    return release_data
}